import { render, staticRenderFns } from "./shikaraGallery.vue?vue&type=template&id=c8b7d726&scoped=true"
import script from "./shikaraGallery.vue?vue&type=script&lang=js"
export * from "./shikaraGallery.vue?vue&type=script&lang=js"
import style0 from "./shikaraGallery.vue?vue&type=style&index=0&id=c8b7d726&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8b7d726",
  null
  
)

export default component.exports